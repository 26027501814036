import { LocalStorageTypes, routes, useLocalStorage } from 'api';
import useSWR from 'swr';

type UserProfile = {
  firstName: string;
  lastName: string;
};

// export const user = 'isanasserapp';
// export const pass = 'T5rL 7PqU 258n tDqv 0hxI W66z';

export function useUser() {
  const { withAuth } = useWithAuthenticationParams();
  return useSWR<UserProfile>(
    withAuth(routes.UserRoutes.me()),
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch user data');
      return response.json();
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
    },
  );
}

export function useAuthenticationParams() {
  const { user, pass } = useLocalStorage({
    user: LocalStorageTypes.STRING,
    pass: LocalStorageTypes.STRING,
  });

  return { user: 'isanasserapp', pass: 'T5rL 7PqU 258n tDqv 0hxI W66z' };
}

export function useWithAuthenticationParams() {
  const { user, pass } = useAuthenticationParams();
  return {
    withAuth: (url: string) => {
      const urlWithAuth = new URL(url);
      urlWithAuth.searchParams.set('user', user);
      urlWithAuth.searchParams.set('pass', pass);
      return urlWithAuth.toString();
    },
  };
}
