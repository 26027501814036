import { routes, useWithAuthenticationParams } from 'api';
import { useState } from 'react';
import useSWRInfinite from 'swr/infinite';

type Transaction = {
  id: number;
  price: number;
  customerName: string;
  date: Date;
  products: {
    id: number;
    name: string;
    imageUrl: string;
    quantity: number;
  }[];
};

export function useTransactions() {
  const { withAuth } = useWithAuthenticationParams();
  return useSWRInfinite<Transaction[]>(
    (index) => {
      return withAuth(routes.TransactionsRoutes.transactions(index + 1));
    },
    async (url) => {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch transactions');
      return response.json();
    },
    {
      initialSize: 1,
      revalidateAll: false,
      revalidateFirstPage: false,
      persistSize: false,
      parallel: true,
    },
  );
}
