import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getOverviewRoutes: RouteListGetter = (lazily) => {
  const OverviewPage = lazily('overview');

  return (
    <>
      <Route path="/overview" element={<OverviewPage />} />
    </>
  );
};

export default getOverviewRoutes;
