import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getPrevSaleRoutes: RouteListGetter = (lazily) => {
  const PrevSalePage = lazily('prev-sale');

  return (
    <>
      <Route path="/prev-sale/:id" element={<PrevSalePage />} />
    </>
  );
};

export default getPrevSaleRoutes;
