import { Color, createTheme, ThemeOptions } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { ColorPartial } from '@mui/material/styles/createPalette';
import ReactRouterLink from 'components/ReactRouterLink';

declare module '@mui/material' {
  interface PaletteColor extends ColorPartial {}
}

const Green: Color = {
  '50': '#e2fde8',
  '100': '#b8f9c6',
  '200': '#80f5a0',
  '300': '#00f074',
  '400': '#00e94d',
  '500': '#00e12e',
  '600': '#00d020',
  '700': '#00bb0a',
  '800': '#00a900',
  '900': '#008800',
  A100: '#d0fdc5',
  A200: '#aefb9e',
  A400: '#59f448',
  A700: '#00c800',
};

const Pink: Color = {
  '50': '#fce2ed',
  '100': '#f8b5d2',
  '200': '#f584b4',
  '300': '#f24c95',
  '400': '#f0007c',
  '500': '#ef0063',
  '600': '#dd0060',
  '700': '#c7005c',
  '800': '#b20058',
  '900': '#8c0052',
  A100: '#97f6f2',
  A200: '#00f0ec',
  A400: '#00dee1',
  A700: '#00aeb1',
};

const themeBase: ThemeOptions = {
  palette: {
    background: {
      default: grey[900],
      paper: grey[900],
    },
    mode: 'dark',
    primary: {
      ...Green,
      main: Green[300],
    },
    secondary: {
      ...Pink,
      main: Pink[400],
    },
    success: Green,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: ReactRouterLink,
      },
    },
  },
};

const theme = createTheme(themeBase);

export default theme;
