import { TransactionRoutes } from './transaction/routes';
import { TransactionsRoutes } from './transactions/routes';
import { UserRoutes } from './user/routes';

export const routes = {
  UserRoutes,
  TransactionRoutes,
  TransactionsRoutes,
};

export function withRouteParams(url: string, params: Record<string, string>) {
  const fullUrl = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    fullUrl.searchParams.append(key, value);
  });
  return fullUrl.toString();
}
