import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getAllSalesRoutes: RouteListGetter = (lazily) => {
  const AllSalesPage = lazily('all-sales');

  return (
    <>
      <Route path="/" element={<AllSalesPage />} />
    </>
  );
};

export default getAllSalesRoutes;
