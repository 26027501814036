import {
  Add,
  Assessment,
  ExitToApp,
  Refresh,
  ShoppingCart,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  ButtonGroup,
  CssBaseline,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useUser } from 'api';
import getAllSalesRoutes from 'pages/all-sales/routes';
import getNewSaleRoutes from 'pages/new-sale/routes';
import getOverviewRoutes from 'pages/overview/routes';
import getPrevSaleRoutes from 'pages/prev-sale/routes';
import React from 'react';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import theme from 'theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppContainer />}>
            {getAllSalesRoutes(lazily)}
            {getNewSaleRoutes(lazily)}
            {getOverviewRoutes(lazily)}
            {getPrevSaleRoutes(lazily)}
          </Route>
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

function AppContainer() {
  const user = useUser();
  const location = useLocation();

  console.dir(JSON.stringify(user));

  if (user.error) {
    return (
      <Stack
        width="100%"
        height="100%"
        bgcolor="black"
        justifyContent="center"
        alignItems="center"
        gap={5}
      >
        <img width="192" height="192" src="./logo512.png" />
        <Stack alignItems="center">
          <Typography variant="h3">We&apos;re Sorry!</Typography>
          <Typography align="center" px={1}>
            You can try to sign out and sign back in, if the error persists
            please try again at a later time
          </Typography>
        </Stack>
        <ButtonGroup size="large">
          <LoadingButton
            variant="contained"
            startIcon={<Refresh />}
            loading={user.isLoading}
            onClick={() => user.mutate()}
          >
            Refresh
          </LoadingButton>
          <Button startIcon={<ExitToApp />}>Sign Out</Button>
        </ButtonGroup>
      </Stack>
    );
  }

  return (
    <Stack height="100%">
      <Stack
        id="app-main-scroller"
        gap={2}
        flexGrow={1}
        px={2}
        pt={4}
        overflow="auto"
      >
        <React.Suspense fallback="Loading...">
          <Outlet />
        </React.Suspense>
      </Stack>
      <BottomNavigation
        showLabels
        value={location.pathname}
        sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <BottomNavigationAction
          label="Sales"
          value="/"
          href="/"
          icon={<ShoppingCart />}
        />
        <BottomNavigationAction
          label="Overview"
          value="/overview"
          href="/overview"
          icon={<Assessment />}
        />
      </BottomNavigation>
    </Stack>
  );
}

function lazily(page: string) {
  return React.lazy(() => import(`pages/${page}`));
}

export type RouteListGetter = (
  lazily: (page: string) => React.LazyExoticComponent<React.ComponentType<any>>,
) => JSX.Element;

export default App;
