import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

type ReactRouterLinkProps = Omit<React.ComponentProps<typeof Link>, 'to'> & {
  href: string;
};

export default forwardRef<
  React.ComponentRef<typeof Link>,
  ReactRouterLinkProps
>(function ReactRouterLink(props, ref) {
  const { href, ...rest } = props;

  return <Link ref={ref} {...rest} to={href} />;
});
