import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getNewSaleRoutes: RouteListGetter = (lazily) => {
  const NewSalePage = lazily('new-sale');

  return (
    <>
      <Route path="/new-sale" element={<NewSalePage />} />
    </>
  );
};

export default getNewSaleRoutes;
