export enum LocalStorageTypes {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export function useLocalStorage(args: Record<string, LocalStorageTypes>) {
  const res: Record<string, any> = {};
  for (const key in args) {
    const keyType = args[key];
    let value: any;
    const rawValue = localStorage.getItem(key);

    switch (keyType) {
      case LocalStorageTypes.STRING:
        if (typeof rawValue === 'string') {
          value = rawValue;
        } else {
          localStorage.setItem(key, '');
          value = '';
        }
        break;
      case LocalStorageTypes.NUMBER:
        if (typeof rawValue === 'string') {
          value = Number(rawValue);
        }
        if (!isNaN(value)) {
          value = rawValue;
        } else {
          localStorage.setItem(key, '0');
          value = 0;
        }
        break;
      case LocalStorageTypes.BOOLEAN:
        if (rawValue === 'true') {
          value = true;
        } else if (rawValue === 'false') {
          value = false;
        } else {
          localStorage.setItem(key, 'false');
          value = false;
        }
        break;
    }

    res[key] = value;
  }

  return res;
}
