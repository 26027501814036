import { baseEndpoint } from 'api/config';
import { withRouteParams } from 'api/routes';

const baseTransactionsEndpoint = baseEndpoint + '/transactions';

export const TransactionsRoutes = {
  base: () => baseTransactionsEndpoint,
  transactions: (page: number) =>
    withRouteParams(TransactionsRoutes.base(), {
      page: `${page}`,
    }),
};
